import React from 'react';
import '../index.css';
import Formulario from './Formulario';

class Kelly extends React.Component{

    render(){
        return (
            <>
                {/* Contact Agent */}
                <div className="col-md-12">
                    <div className="row section-t3">
                        <div className="col-sm-12">
                            <div className="title-box-d">
                            <h3 className="title-d">Contacto Inmobiliario</h3>
                            </div>
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <img loading='lazy' src="assets/img/agent-1.jpg" alt="Agent" className="img-fluid" />
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="property-agent">
                            <h4 className="title-agent">Kelly Vasquez</h4>
                            <p className="color-text-a">
                            Soy una Agente Inmobiliaria Titulada y Especializada en la Gestión y Distribución de Bienes en el Mercado Inmobiliario de Perú. 
                            </p>
                            <p className='no-margin'>
                            Ofrezco Servicios de Asesoramiento para la Compra, Venta y Alquiler de Propiedades, Garantizando una Experiencia Profesional y Confiable para Mis Clientes.
                            </p>
                            <ul className="list-unstyled">
                                <li className="d-flex justify-content-between">
                                <strong>Celular 1:</strong>
                                <span className="color-text-a">+51 989 164 022</span>
                                </li>
                                <li className="d-flex justify-content-between">
                                <strong>Celular 2:</strong>
                                <span className="color-text-a">+51 907 416 805</span>
                                </li>
                                <li className="d-flex justify-content-between">
                                <strong>Email:</strong>
                                <span className="color-text-a">kinmobiliaria240@gmail.com</span>
                                </li>
                            </ul>
                            <div className="socials-a">
                            </div>
                            </div>
                        </div>

                        <Formulario />

                    </div>
                </div>
            </>
        );
    }
}

export default Kelly;