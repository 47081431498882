import React from 'react';

import { Link } from 'react-router-dom';

class Casas extends React.Component{

    constructor() {
        super();
        this.state = {
          properties: [
            {
              id: 1,
              title: 'Los Olivos',
              location: 'Calle las Fresas , Las Palmeras',
              type: 'Venta', // Cambia aquí el tipo de propiedad
              price: '$200.000',
              area: '183m²',
              bedrooms: 3,
              bathrooms: 2,
              garages: 1,
              image: 'assets/img/casas/foto-1.jpg',
            },
            {
              id: 2,
              title: 'Santiago de Surco',
              location: 'Estacion Ayacucho',
              type: 'Venta', // Cambia aquí el tipo de propiedad
              price: '$350.000',
              area: '190m²',
              bedrooms: 3,
              bathrooms: 3,
              garages: 1,
              image: 'assets/img/casas/foto-2.jpg',
            },
          ],
          filterValue: 'all', // Valor inicial del filtro
        };
      }
    
      handleFilterChange = (e) => {
        this.setState({ filterValue: e.target.value });
      };

    render(){
        const { properties, filterValue } = this.state;

        // Filtrar las propiedades según el valor del filtro
        const filteredProperties =
          filterValue === 'all'
            ? properties
            : properties.filter((property) => property.type === filterValue);

        return (
            <>
              <main id="main">
          {/* ======= Intro Single ======= */}
          <section className="intro-single">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-8">
                  <div className="title-single-box">
                    <h1 className="title-single">Nuestras Casas</h1>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <nav
                    aria-label="breadcrumb"
                    className="breadcrumb-box d-flex justify-content-lg-end"
                  >
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/home"> Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Casas
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </section>
          {/* End Intro Single */}

          {/* ======= Property Grid ======= */}
          <section className="property-grid grid">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="grid-option">
                    <form>
                      <select
                        className="custom-select"
                        onChange={this.handleFilterChange}
                      >
                        <option value="all">Todos</option>
                        <option value="Venta">En Venta</option>
                        <option value="Alquiler">En Alquiler</option>
                      </select>
                    </form>
                  </div>
                </div>

                {/* Mapea las propiedades filtradas para mostrarlas */}
                {filteredProperties.map((property) => (
                  <div className="col-md-4" key={property.id}>
                    <Link to={`/casa${property.id}`}>
                    <div className="card-box-a card-shadow">
                      <div className="img-box-a">
                        <img
                          loading='lazy'
                          src={property.image}
                          alt=""
                          className="img-a img-fluid"
                        />
                      </div>
                      <div className="card-overlay">
                        <div className="card-overlay-a-content">
                          <div className="card-header-a">
                            <h2 className="card-title-a">
                              <Link to={`/casa${property.id}`}>
                                {property.title}
                                <br /> {property.location}
                              </Link>
                            </h2>
                          </div>
                          <div className="card-body-a">
                            <div className="price-box d-flex">
                              <span className="price-a">
                                {property.type} | {property.price}
                              </span>
                            </div>
                            <Link className="link-a" to={`/casa${property.id}`}>
                              Click para ver
                              <span className="bi bi-chevron-right"></span>
                            </Link>
                          </div>
                          <div className="card-footer-a">
                            <ul className="card-info d-flex justify-content-around">
                              <li>
                                <h4 className="card-info-title">Area</h4>
                                <span>{property.area}</span>
                              </li>
                              <li>
                                <h4 className="card-info-title">Dormitorios</h4>
                                <span>{property.bedrooms}</span>
                              </li>
                              <li>
                                <h4 className="card-info-title">Baños</h4>
                                <span>{property.bathrooms}</span>
                              </li>
                              <li>
                                <h4 className="card-info-title">Garages</h4>
                                <span>{property.garages}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </section>
          {/* End Property Grid */}
        </main>
            </>
        );
    }
}

export default Casas;