import React from 'react';
import { Link } from 'react-router-dom';


class Footer extends React.Component{
    handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };

    render(){
        return (
            <div>
                <section class="section-footer">
                    <div class="container">
                    <div class="row footer2">
                        <div class="col-sm-12 col-md-7">
                        <div class="widget-a">
                            <div class="w-header-a">
                            <h3 class="w-title-a text-brand-footer">Kelta Inmobiliaria</h3>
                            </div>
                            <div class="w-body-a">
                            <p class="w-text-a color-text-a">
                                Somos expertos en bienes raíces, enfocados en ventas y alquileres excepcionales. 
                                Ofrecemos asesoramiento personalizado y transparencia en cada transacción. Confía en nosotros para tus necesidades inmobiliarias.
                            </p>
                            </div>
                            <div class="w-footer-a">
                            <ul class="list-unstyled">
                                <li class="color-a">
                                <span class="color-text-a">Email:</span> informes@keltainmobiliaria.com
                                </li>
                                <li class="color-a">
                                <span class="color-text-a">Celular:</span> +51 989 164 022
                                </li>
                            </ul>
                            </div>
                        </div>
                        </div>
                        {/* 
                        <div class="col-sm-12 col-md-4 section-md-t3">
                        <div class="widget-a">
                            <div class="w-header-a">
                            <h3 class="w-title-a text-brand">Ubicaciones</h3>
                            </div>
                            <div class="w-body-a">
                            <ul class="list-unstyled">
                                <li class="item-list-a">
                                <i class="bi bi-chevron-right"></i> <Link>San Miguel</Link>
                                </li>
                                <li class="item-list-a">
                                <i class="bi bi-chevron-right"></i> <Link>Magdalena</Link>
                                </li>
                                <li class="item-list-a">
                                <i class="bi bi-chevron-right"></i> <Link>San Isidro</Link>
                                </li>
                                <li class="item-list-a">
                                <i class="bi bi-chevron-right"></i> <Link>Surco</Link>
                                </li>
                                <li class="item-list-a">
                                <i class="bi bi-chevron-right"></i> <Link>Jesus Maria</Link>
                                </li>
                            </ul>
                            </div>
                        </div>
                        

                        </div>
                        */}
                    </div>
                    </div>
                </section>
                <footer>
                    <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                        <nav class="nav-footer">
                            <ul class="list-inline">
                            <li class="list-inline-item">
                                <Link to="/home">Inicio</Link>
                            </li>
                            <li class="list-inline-item">
                                <Link to="/casas">Casas</Link>
                            </li>
                            <li class="list-inline-item">
                                <Link to="/departamentos">Departamentos</Link>
                            </li>
                            <li class="list-inline-item">
                                <Link to="/terrenos">Terrenos</Link>
                            </li>
                            <li class="list-inline-item">
                                <Link to="/locales">Locales Comercial</Link>
                            </li>
                            <li class="list-inline-item">
                                <Link to="/about">Nosotros</Link>
                            </li>
                            </ul>
                        </nav>
                        <div class="socials-a">
                            <ul class="list-inline">
                            <li class="list-inline-item">
                                <a href="https://www.facebook.com/profile.php?id=100063175992764" target='blank'>
                                <i class="bi bi-facebook" aria-hidden="true"></i>
                                </a>
                            </li>
                            </ul>
                        </div>
                        <div class="copyright-footer">
                            <p class="copyright color-text-a">
                            &copy; Copyright
                            <span class="color-a">Kelta Inmobiliaria</span> All Rights Reserved.
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </footer>
                {/* 
                <div id="preloader"></div>
                */}
            <div
            className="back-to-top d-flex align-items-center justify-content-center"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={this.handleScrollToTop}
            >
                
          <i className="bi bi-arrow-up-short"></i>
        </div>

            </div>
        );
    }
}

export default Footer;