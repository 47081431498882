import React from 'react';
import '../index.css';

class About extends React.Component {
  render() {
    return (
      <>
        <section className="section-services section-t8">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mision-arriba row">
                <div className="title-box mision-title">
                    <h2 className="title-a">Nuestros Servicios</h2>
                </div>
                <div className="title-wrap d-flex justify-content-between">
                  
                  <div className="row">
                    <ServiceItem iconClass="bi bi-file-earmark-text" title="Área Legal" />
                    <ServiceItem iconClass="bi bi-cash" title="Financiamiento Bancario" />
                    <ServiceItem iconClass="bi bi-file-earmark-text" title="Gestión de Trámites" />
                    <ServiceItem iconClass="bi bi-search" title="Investigación y Análisis Inmobiliario" />
                    <ServiceItem iconClass="bi bi-building" title="Inversiones Inmobiliarias" />
                    <ServiceItem iconClass="bi bi-tools" title="Proyectos de arquitectura y remodelaciones" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mision">
              <div className="col-md-4">
                <div className="card-box-c foo">
                  <div className="card-header-c d-flex">
                    <div className="card-box-ico">
                      <span className="bi bi-bullseye"></span>
                    </div>
                    <div className="card-title-c align-self-center">
                      <h2 className="title-c">MISIÓN</h2>
                    </div>
                  </div>
                  <div className="card-body-c">
                    <p className="content-c">
                      Empresa dedicada a brindar soluciones en el rubro inmobiliario para cubrir y satisfacer las necesidades que nuestros clientes requieran, basando nuestro servicio en la responsabilidad, eficacia. 
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-box-c foo">
                  <div className="card-header-c d-flex">
                    <div className="card-box-ico">
                      <span className="bi bi-eye"></span>
                    </div>
                    <div className="card-title-c align-self-center">
                      <h2 className="title-c">VISIÓN</h2>
                    </div>
                  </div>
                  <div className="card-body-c">
                    <p className="content-c">
                      Afianzar el posicionamiento y liderazgo en el sector inmobiliario comprometiéndonos a mantener el profesionalismo y ética con sus clientes en los procesos administrativos de búsqueda y compra para ofrecer soluciones y éxito en todas las operaciones.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const ServiceItem = ({ iconClass, title }) => {
  return (
    <div className="col-md-4">
      <div className="service-item text-center">
        <div className="circle-icon">
          <i className={iconClass}></i>
        </div>
        <h3>{title}</h3>
      </div>
    </div>
  );
};

export default About;