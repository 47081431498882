import React from 'react';
import { Link } from 'react-router-dom';
import Kelly from '../agentes/Kelly';
import { Swiper, SwiperSlide } from 'swiper/react';

class Casa1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: props.images || [
        'assets/img/casas/casa2-foto1.jpg',
        'assets/img/casas/casa2-foto2.jpg',
        'assets/img/casas/casa2-foto3.jpg',
        'assets/img/casas/casa2-foto4.jpg',
        'assets/img/casas/casa2-foto5.jpg',
        'assets/img/casas/casa2-foto6.jpg',
        'assets/img/casas/casa2-foto7.jpg',
        'assets/img/casas/casa2-foto8.jpg',
        'assets/img/casas/casa2-foto9.jpg',
      ],
      price: props.price || '350.000',
      address: props.address || 'Estacion Ayacucho',
      distrito: props.distrito || 'Santiago de Surco',
      propertyType: props.propertyType || 'Casa',
      status: props.status || 'Venta',
      area: props.area || '190m²',
      bedrooms: props.bedrooms || '3',
      bathrooms: props.bathrooms || '3',
      garage: props.garage || '1',
      description: props.description || 'En muy buen estado, excelente distribución con áreas verdes, bonitos y tranquilos ambientes dentro y fuera de la casa. Cuenta con: 03 dormitorios, 2 baños 01 medio baño, Cuarto de servicio completo, Zona de lavandería, Cisterna y tanque elevado, Patio y jardín, 01 cochera interior, Cocina con muebles altos y bajos, Acabados muy bien conservados, pisos de madera, Cocina y baños remodelados',
      amenities: props.amenities || [
        'Patio',
        'Area de lavandería',
        'cochera ',
        'Desagüe',
        'jardín ',
        'Cocina  ',
        'pisos de madera ',
      ],
      location: props.location || 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.669724488745!2d-76.99955818937059!3d-12.13473548805938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105b80f31375a41%3A0x26f71de1ad08c47!2sEstaci%C3%B3n%20Ayacucho!5e0!3m2!1sen!2spe!4v1697456464601!5m2!1sen!2spe',
    };
  }

  render() {
    const {
      images,
      price,
      address,
      distrito,
      propertyType,
      status,
      area,
      bedrooms,
      bathrooms,
      garage,
      description,
      description2,
      amenities,
      video,
      floorPlans,
      location,
    } = this.state;

    return (
      <>
        {/* Contenido del componente */}
        <main id="main">
          {/* Intro Single */}
          <section className="intro-single">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-8">
                  <div className="title-single-box">
                    <h1 className="title-single">{address}</h1>
                    <span className="color-text-a">{distrito}</span>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <nav aria-label="breadcrumb" className="breadcrumb-box d-flex justify-content-lg-end">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/home">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/casas">Casas</Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Casa 2
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </section>

    {/* Property Single */}
    <section className="property-single nav-arrow-b">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 carousel-menu">
                  <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    loop={true}
                    autoplay={{ delay: 5000 }}
                  >
                    {images.map((image, index) => (
                      <SwiperSlide key={index} className="carousel-item-b swiper-slide">
                        <img loading='lazy' className='container-extra' src={image} alt={`Property ${index + 1}`} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>

              {/* Detalles de la propiedad */}
              <div className="row">
                {/* Precio y resumen */}
                <div className="col-md-5 col-lg-4">
                  <div className="property-price d-flex justify-content-center foo">
                    <div className="card-header-c d-flex">
                      <div className="card-box-ico">
                        <span className="bi bi-cash">$</span>
                      </div>
                      <div className="card-title-c align-self-center">
                        <h5 className="title-c">{price}</h5>
                      </div>
                    </div>
                  </div>
                  {/* Detalles rápidos */}
                  <div className="property-summary">
                    <div className="title-box-d section-t4">
                      <h3 className="title-d">Detalles</h3>
                    </div>
                    <div className="summary-list">
                      <ul className="list">
                        <li className="d-flex justify-content-between">
                          <strong>Dirección:</strong>
                          <span>{address}</span>
                        </li>
                        <li className="d-flex justify-content-between">
                          <strong>Tipo de Propiedad:</strong>
                          <span>{propertyType}</span>
                        </li>
                        <li className="d-flex justify-content-between">
                          <strong>Estado:</strong>
                          <span>{status}</span>
                        </li>
                        <li className="d-flex justify-content-between">
                          <strong>Área:</strong>
                          <span>{area}</span>
                        </li>
                        <li className="d-flex justify-content-between">
                          <strong>Dormitorios:</strong>
                          <span>{bedrooms}</span>
                        </li>
                        <li className="d-flex justify-content-between">
                          <strong>Baños:</strong>
                          <span>{bathrooms}</span>
                        </li>
                        <li className="d-flex justify-content-between">
                          <strong>Estacionamientos:</strong>
                          <span>{garage}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* Descripción y comodidades */}
                <div className="col-md-7 col-lg-7 section-md-t3">
                  <div className="title-box-d">
                    <h3 className="title-d">Descripción</h3>
                  </div>
                  {/* Descripción de la propiedad */}
                  <div className="property-description">
                    <p className="description color-text-a">{description}</p>
                    <p className="description color-text-a">{description2}</p>
                  </div>

                  {/* Lista de comodidades */}
                  <div className="title-box-d">
                    <h3 className="title-d">Comodidades</h3>
                  </div>
                  <div className="amenities-list color-text-a">
                    <ul className="list-a no-margin">
                      {amenities.map((amenity, index) => (
                        <li key={index}>{amenity}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              {/* Pestañas de la propiedad */}
              <div className="col-md-10 offset-md-1">
                <ul className="nav nav-pills-a nav-pills mb-3 section-t3" id="pills-tab" role="tablist">
                  {video && (
                    <li className="nav-item">
                      <a className="nav-link" id="pills-video-tab" data-bs-toggle="pill" href="#pills-video" role="tab" aria-controls="pills-video" aria-selected="true">
                        Video
                      </a>
                    </li>
                  )}
                  {floorPlans && (
                    <li className="nav-item">
                      <a className="nav-link" id="pills-plans-tab" data-bs-toggle="pill" href="#pills-plans" role="tab" aria-controls="pills-plans" aria-selected="false">
                        Planos
                      </a>
                    </li>
                  )}
                  {location && (
                    <li className="nav-item">
                      <a className="nav-link active" id="pills-map-tab" data-bs-toggle="pill" href="#pills-map" role="tab" aria-controls="pills-map" aria-selected="false">
                        Ubicación
                      </a>
                    </li>
                  )}
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  {/* Pestaña de video */}
                  {video && (
                    <div className="tab-pane fade" id="pills-video" role="tabpanel" aria-labelledby="pills-video-tab">
                      <iframe src={video} width="100%" height="460" frameBorder="0" allowFullScreen title="Property Video"></iframe>
                    </div>
                  )}

                  {/* Pestaña de planos */}
                  {floorPlans && (
                    <div className="tab-pane fade" id="pills-plans" role="tabpanel" aria-labelledby="pills-plans-tab">
                      <img loading='lazy' src={floorPlans} alt="Floor Plans" className="img-fluid" />
                    </div>
                  )}

                  {/* Pestaña de ubicación */}
                  {location && (
                    <div className="tab-pane fade show active" id="pills-map" role="tabpanel" aria-labelledby="pills-map-tab">
                      <iframe src={location} width="100%" height="460" frameBorder="0" style={{ border: 0 }} allowFullScreen title="Property Location"></iframe>
                    </div>
                  )}
                </div>
              </div>
                          {/* Agente inmobiliario */}
            <Kelly />
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default Casa1;