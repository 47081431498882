import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet"
import { BrowserRouter } from 'react-router-dom'

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './paginas/Home';
import About from './paginas/About';
import Casas from './paginas/Casas';
import Departamentos from './paginas/Departamentos';
import Departamento1 from './departamentos/Departamento1';
import Departamento2 from './departamentos/Departamento2';
import Departamento3 from './departamentos/Departamento3';
import Departamento4 from './departamentos/Departamento4';
import Departamento5 from './departamentos/Departamento5';
import Departamento6 from './departamentos/Departamento6';
import Departamento7 from './departamentos/Departamento7';
import Departamento8 from './departamentos/Departamento8';
import Departamento9 from './departamentos/Departamento9';
import Casa1 from './casas/Casa1';
import Casa2 from './casas/Casa2';
import Terrenos from './paginas/Terrenos';
import Terreno1 from './terrenos/Terreno1';
import Terreno2 from './terrenos/Terreno2';
import Terreno3 from './terrenos/Terreno3';
import Terreno4 from './terrenos/Terreno4';
import Locales from './paginas/Locales';
import Local1 from './locales/Local1';
import Local2 from './locales/Local2';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDMEzHC8c0yipsMGr5fVCAUKxRWt4quX2M",
  authDomain: "keltainmobiliaria.firebaseapp.com",
  projectId: "keltainmobiliaria",
  storageBucket: "keltainmobiliaria.appspot.com",
  messagingSenderId: "907436852196",
  appId: "1:907436852196:web:d7e8a0e7fa9d296fcbfdf1",
  measurementId: "G-YJ7WGPDGJ0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);



const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => { 
  return (
    <>
    <Helmet>
      <script src="assets/vendor/swiper/swiper-bundle.min.js" />
      <script src="assets/vendor/php-email-form/validate.js" />
      <script src="assets/js/main.js" />
    </Helmet>
    <BrowserRouter>  
    <ScrollToTop />
    <Header/>
    <Routes>
      <Route path='/' element={< Home />}  />
      <Route path='/home' element={< Home />}  />
      <Route path='/about' element={< About />}  />
      <Route path='/departamentos' element={< Departamentos />}  />
      <Route path='/departamento1' element={< Departamento1 />}  />
      <Route path='/departamento2' element={< Departamento2 />}  />
      <Route path='/departamento3' element={< Departamento3 />}  />
      <Route path='/departamento4' element={< Departamento4 />}  />
      <Route path='/departamento5' element={< Departamento5 />}  />
      <Route path='/departamento6' element={< Departamento6 />}  />
      <Route path='/departamento7' element={< Departamento7 />}  />
      <Route path='/departamento8' element={< Departamento8 />}  />
      <Route path='/departamento9' element={< Departamento9 />}  />
      <Route path='/casas' element={< Casas />}  />
      <Route path='/casa1' element={< Casa1 />}  />
      <Route path='/casa2' element={< Casa2 />}  />
      <Route path='/terrenos' element={< Terrenos />}  />
      <Route path='/terreno1' element={< Terreno1 />}  />
      <Route path='/terreno2' element={< Terreno2 />}  />
      <Route path='/terreno3' element={< Terreno3 />}  />
      <Route path='/terreno4' element={< Terreno4 />}  />
      <Route path='/locales' element={< Locales />}  />
      <Route path='/local1' element={< Local1 />}  />
      <Route path='/local2' element={< Local2 />}  />
    </Routes>
    <Footer/>
    </BrowserRouter> 
    </>
  );
};

export default App;