import React, { useRef, useState } from 'react';
import '../index.css';
import emailjs from '@emailjs/browser';

const Formulario = () => {
    const form = useRef();
    const [isFormValid, setIsFormValid] = useState(true); // Estado para validar el formulario
    const [isEmailSent, setIsEmailSent] = useState(false); // Estado para verificar si el correo se ha enviado

    const sendEmail = (e) => {
        e.preventDefault();

        // Validar que todos los campos estén llenos
        const isValid = form.current.user_name.value && form.current.user_email.value && form.current.message.value;
        setIsFormValid(isValid);

        if (isValid) {
            emailjs.sendForm('service_l7of5k9', 'template_rdr3jiv', form.current, 'fyXfWoXR3_mwhmALV')
                .then((result) => {
                    console.log(result.text);
                    console.log("mensaje enviado");
                    setIsEmailSent(true); // Establecer el estado para mostrar el mensaje de éxito
                    // Limpiar los datos de las casillas después de enviar el correo
                    form.current.reset();
                }, (error) => {
                    console.log(error.text);
                    console.log("error al enviar mensaje");
                });
        }
    };

    // Cerrar el mensaje emergente
    const closePopup = () => {
        setIsEmailSent(false);
    };

    return (
        <>
            <div className="col-md-12 col-lg-4">
                <div className="property-contact">
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="col-md-12 mb-1">
                            <input className="form-control form-control-lg form-control-a" type="text" name="user_name" placeholder="Nombre *" />
                        </div>
                        <div className="col-md-12 mb-1">
                            <input className="form-control form-control-lg form-control-a" type="email" name="user_email" placeholder="Correo *" />
                        </div>
                        
                        <div className="col-md-12 mb-1">
                            <textarea className="form-control" name="message" placeholder="Mensaje *" cols="45" rows="8" />
                        </div>
                        
                        <div className="col-md-12 mt-3">
                            <input className="btn btn-a" type="submit" value="Enviar" />
                        </div>
                        {!isFormValid && <p className="error-message">Por favor, llene todos los campos.</p>}
                        {isEmailSent && (
                            <div className="popup">
                                <p>Correo enviado satisfactoriamente!</p>
                                <button onClick={closePopup}>Cerrar</button>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    );
};

export default Formulario;