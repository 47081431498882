import React from 'react';
import { Link } from 'react-router-dom';
import '../index.css';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false,
        };
    }

    handleNavToggle = () => {
        this.setState(prevState => ({
            isNavOpen: !prevState.isNavOpen,
        }));
    };

    handleNavLinkClick = () => {
        // Cerrar el menú móvil después de hacer clic en una opción del menú
        this.setState({
            isNavOpen: false,
        });
    };

    render() {
        const { isNavOpen } = this.state;
        return (
            <div>
                {/* Header/Navbar */}
                <nav className="navbar navbar-default navbar-trans navbar-expand-lg fixed-top">
                    <div className="container">
                        <button
                            className={`navbar-toggler ${isNavOpen ? "" : "collapsed"}`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarDefault"
                            aria-controls="navbarDefault"
                            aria-expanded={isNavOpen ? "true" : "false"}
                            aria-label="Toggle navigation"
                            onClick={this.handleNavToggle}
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        <Link className="navbar-brand text-brand" to="home" onClick={this.handleNavLinkClick}>
                            <img src='assets/img/Logo-dorado.png' className='logo' alt='logo' />
                            elta <span className="header-inm">Inmobiliaria</span>
                        </Link>

                        <div className={`navbar-collapse collapse justify-content-center ${isNavOpen ? "show" : ""}`} id="navbarDefault">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link" to="home" onClick={this.handleNavLinkClick}>Inicio</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="casas" onClick={this.handleNavLinkClick}>Casas</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="departamentos" onClick={this.handleNavLinkClick}>Departamentos</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="terrenos" onClick={this.handleNavLinkClick}>Terrenos</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="locales" onClick={this.handleNavLinkClick}>Local Comercial</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="about" onClick={this.handleNavLinkClick}>Nosotros</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

export default Header;