import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SwiperCore from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';


SwiperCore.use([Autoplay, Pagination]);

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      introSlides: [
        {
          imageUrl: 'assets/img/slide-1.jpg',
          location: 'Calle Antequera 580, San Isidro',
          title: 'Venta',
          link: 'departamento2',
          subtitle: 'San Isidro',
          price: 'Venta | $ 136.000',
        },
        {
          imageUrl: 'assets/img/slide-2.jpg',
          location: 'CALLE ROUSSEAU 295, San Borja Norte',
          title: 'Alquiler',
          link: 'departamento4',
          subtitle: 'San Borja',
          price: 'Alquiler | $ 700',
        },
        {
          imageUrl: 'assets/img/slide-3.jpg',
          location: 'ESTACION AYACUCHO, Santiago de Surco',
          title: 'Venta',
          link: 'casa2',
          subtitle: 'Santiago de Surco',
          price: 'Venta | $ 350.000',
        },
      ],
      Casas: [
        {
          id: 1,
          title: 'Los Olivos',
          location: 'Calle las Fresas , Las Palmeras',
          type: 'Venta', // Cambia aquí el tipo de propiedad
          price: '$200.000',
          area: '183m²',
          bedrooms: 3,
          bathrooms: 2,
          garages: 1,
          image: 'assets/img/casas/foto-1.jpg',
        },
        {
          id: 2,
          title: 'Santiago de Surco',
          location: 'Estacion Ayacucho',
          type: 'Venta', // Cambia aquí el tipo de propiedad
          price: '$350.000',
          area: '190m²',
          bedrooms: 3,
          bathrooms: 3,
          garages: 1,
          image: 'assets/img/casas/foto-2.jpg',
        },
      ],
      Departamentos: [
        {
          id: 1,
          title: 'San Miguel',
          location: 'Av. Razuri 235, Maranga',
          type: 'Venta', // Cambia aquí el tipo de propiedad
          price: '$280.000',
          area: '167m²',
          bedrooms: 3,
          bathrooms: 3,
          garages: 1,
          image: 'assets/img/departamentos/foto-1.jpg',
        },
        {
            id: 2,
            title: 'San Isidro',
            location: 'Calle Antequera 580',
            type: 'Venta', // Cambia aquí el tipo de propiedad
            price: '$136.000',
            area: '45m²',
            bedrooms: 1,
            bathrooms: 1,
            garages: 0,
            image: 'assets/img/departamentos/foto-2.jpg',
          },
          {
            id: 3,
            title: 'Punta Negra',
            location: 'Asociacion Juventud El Bosque',
            type: 'Alquiler', // Cambia aquí el tipo de propiedad
            price: '$450',
            area: '70m²',
            bedrooms: 2,
            bathrooms: 2,
            garages: 1,
            image: 'assets/img/departamentos/foto-3.jpg',
          },
          {
            id: 4,
            title: 'San Borja',
            location: 'CALLE ROUSSEAU 295, San Borja Norte',
            type: 'Alquiler', // Cambia aquí el tipo de propiedad
            price: '$700',
            area: '55m²',
            bedrooms: 1,
            bathrooms: 1,
            garages: 0,
            image: 'assets/img/departamentos/foto-4.jpg',
          },
          {
            id: 5,
            title: 'San Miguel',
            location: 'Manco Segundo cdra, Maranga',
            type: 'Alquiler', // Cambia aquí el tipo de propiedad
            price: '$486',
            area: '60m²',
            bedrooms: 1,
            bathrooms: 1,
            garages: 0,
            image: 'assets/img/departamentos/foto-5.jpg',
          },
          {
            id: 6,
            title: 'Santiago de Surco',
            location: 'Av. el golf los incas , Club Golf Los Incas',
            type: 'Venta', // Cambia aquí el tipo de propiedad
            price: '$290.000',
            area: '168m²',
            bedrooms: 3,
            bathrooms: 3,
            garages: 1,
            image: 'assets/img/departamentos/foto-6.jpg',
          },
          {
            id: 7,
            title: 'San Isidro',
            location: 'AV. EL GOLF , Golf',
            type: 'Venta', // Cambia aquí el tipo de propiedad
            price: '$450.000',
            area: '208m²',
            bedrooms: 3,
            bathrooms: 3,
            garages: 3,
            image: 'assets/img/departamentos/foto-7.jpg',
          },
          {
            id: 8,
            title: 'San Isidro',
            location: 'Calle los Pinos 561',
            type: 'Alquiler', // Cambia aquí el tipo de propiedad
            price: '$700',
            area: '46m²',
            bedrooms: 1,
            bathrooms: 1,
            garages: 0,
            image: 'assets/img/departamentos/foto-8.jpg',
          },
          {
            id: 9,
            title: 'San Miguel',
            location: 'Av. los patriotas 589',
            type: 'Venta',
            price: 'S/.435,000',
            area: '72m²',
            bedrooms: 3,
            bathrooms: 2,
            garages: 0,
            image: 'assets/img/departamentos/foto-9.jpg',
          },
      ],
      Terrenos: [
        {
            id: 1,
            title: 'Asia',
            location: 'Condominio la pradera de asia',
            type: 'Venta', // Cambia aquí el tipo de propiedad
            price: '$53.000',
            area: '309m²',
            image: 'assets/img/terrenos/foto-1.jpg',
          },
          {
            id: 2,
            title: 'San Miguel',
            location: 'Calle Independencia 325',
            type: 'Venta', // Cambia aquí el tipo de propiedad
            price: '$1.050.000',
            area: '600m²',
            image: 'assets/img/terrenos/foto-2.jpg',
          },
          {
            id: 3,
            title: 'Chorrillos',
            location: 'Jr. pacto andino',
            type: 'Venta', // Cambia aquí el tipo de propiedad
            price: '$1.225.000',
            area: '1400m²',
            image: 'assets/img/terrenos/foto-3.jpg',
          },
          {
            id: 4,
            title: 'San Miguel',
            location: 'Calle mariscal agustin gamarra',
            type: 'Venta', // Cambia aquí el tipo de propiedad
            price: '$1.000.000',
            area: '765m²',
            image: 'assets/img/terrenos/foto-4.jpg',
          },
      ],
      Locales: [
        {
          id: 1,
          title: 'San Isidro',
          location: 'Av. Salaverry 2375',
          type: 'Alquiler', // Cambia aquí el tipo de propiedad
          price: '$6.000',
          areat: '795m²',
          areac: '760m²',
          image: 'assets/img/locales/foto-1.jpg',
        },
        {
          id: 2,
          title: 'Jesús María',
          location: 'Av. Salaverry',
          type: 'Alquiler', // Cambia aquí el tipo de propiedad
          price: '$3.500',
          areat: '500m²',
          areac: '72m²',
          image: 'assets/img/locales/foto-2.jpg',
        },
      ],
    };
  }

  render() {
    const { introSlides, Casas, Departamentos, Terrenos, Locales } = this.state;

    return (
      <>
        <div className="intro intro-carousel swiper position-relative">
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true }}
            loop={true}
            autoplay={{ delay: 5000 }}
            navigation={false} // Deshabilitar las flechas de navegación
          >
            {introSlides.map((slide, index) => (
              <SwiperSlide key={index} className="swiper-slide carousel-item-a intro-item bg-image" style={{ backgroundImage: `url(${slide.imageUrl})` }}>
                <div className="overlay overlay-a"></div>
                <div className="intro-content display-table">
                  <div className="table-cell">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="intro-body">
                            <p className="intro-title-top">{slide.location}</p>
                            <h1 className="intro-title mb-4 ">
                              <span className="color-b">{slide.title}</span>
                              <br /> {slide.subtitle}
                            </h1>
                            <p className="intro-subtitle intro-price">
                              <Link to={`/${slide.link}`}>
                                <span className="price-a">{slide.price}</span>
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <main id="main">
          <section className="section-property section-t8">

          
            {/* ======= Departamentos ======= */}

            <div className="container">
            <div className="row">
                <div className="col-md-12">
                  <div className="title-wrap d-flex flex-column flex-md-row justify-content-between align-items-md-center">
                  <div className="title-box order-md-1 mb-md-0">
                    <h2 className="title-a">Departamentos</h2>
                  </div>
                  <div className="title-link order-md-2">
                    <Link to="/departamentos">
                      Todos los departamentos <span className="bi bi-chevron-right"></span>
                    </Link>
                  </div>
                </div>
                </div>
              </div>

              <div className='carousel-menu'>
              <Swiper
                id="property-carousel"
                spaceBetween={30}
                slidesPerView={3}
                pagination={{ clickable: true }}
                loop={true}
                autoplay={{ delay: 5000 }}
                breakpoints={{
                  900: {
                    slidesPerView: 3, // Para pantallas de 900px o más grandes
                  },
                  0: {
                    slidesPerView: 1, // Para pantallas más pequeñas que 900px
                  }
                }}
              >
                {Departamentos.map((property, index) => (
                  <SwiperSlide key={index} className="carousel-item-b swiper-slide">
                    <Link to={`/departamento${property.id}`}>
                    <div className="card-box-a card-shadow">
                      <div className="img-box-a">
                        <img
                          loading='lazy'
                          src={property.image}
                          alt=""
                          className="img-a img-fluid"
                        />
                      </div>
                      <div className="card-overlay">
                        <div className="card-overlay-a-content">
                          <div className="card-header-a">
                            <h2 className="card-title-a">
                              <Link to={`/departamento${property.id}`}>
                                {property.title}
                                <br /> {property.location}
                              </Link>
                            </h2>
                          </div>
                          <div className="card-body-a">
                            <div className="price-box d-flex">
                              <span className="price-a">
                                {property.type} | {property.price}
                              </span>
                            </div>
                            <Link
                              className="link-a"
                              to={`/departamento${property.id}`}
                            >
                              Click para ver
                              <span className="bi bi-chevron-right"></span>
                            </Link>
                          </div>
                          <div className="card-footer-a">
                            <ul className="card-info d-flex justify-content-around">
                              <li>
                                <h4 className="card-info-title">Area</h4>
                                <span>{property.area}</span>
                              </li>
                              <li>
                                <h4 className="card-info-title">Dormitorios</h4>
                                <span>{property.bedrooms}</span>
                              </li>
                              <li>
                                <h4 className="card-info-title">Baños</h4>
                                <span>{property.bathrooms}</span>
                              </li>
                              <li>
                                <h4 className="card-info-title">Garages</h4>
                                <span>{property.garages}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
              </div>
              <div className="propery-carousel-pagination carousel-pagination"></div>
            </div>
            
            {/* ======= Casas ======= */}

            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="title-wrap d-flex flex-column flex-md-row justify-content-between align-items-md-center">
                    <div className="title-box order-md-1 mb-md-0">
                      <h2 className="title-a">Casas</h2>
                    </div>
                    <div className="title-link order-md-2">
                      <Link to="/casas">
                        Todos las casas <span className="bi bi-chevron-right"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className='carousel-menu'>
              <Swiper
                id="property-carousel"
                spaceBetween={30}
                slidesPerView={3}
                pagination={{ clickable: true }}
                loop={true}
                autoplay={{ delay: 5000 }}
                breakpoints={{
                  900: {
                    slidesPerView: 3, // Para pantallas de 900px o más grandes
                  },
                  0: {
                    slidesPerView: 1, // Para pantallas más pequeñas que 900px
                  }
                }}
              >
                {Casas.map((property, index) => (
                  <SwiperSlide key={index} className="carousel-item-b swiper-slide">
                    <Link to={`/departamento${property.id}`}>
                    <div className="card-box-a card-shadow">
                      <div className="img-box-a">
                        <img
                          loading='lazy'
                          src={property.image}
                          alt=""
                          className="img-a img-fluid"
                        />
                      </div>
                      <div className="card-overlay">
                        <div className="card-overlay-a-content">
                          <div className="card-header-a">
                            <h2 className="card-title-a">
                              <Link to={`/departamento${property.id}`}>
                                {property.title}
                                <br /> {property.location}
                              </Link>
                            </h2>
                          </div>
                          <div className="card-body-a">
                            <div className="price-box d-flex">
                              <span className="price-a">
                                {property.type} | {property.price}
                              </span>
                            </div>
                            <Link
                              className="link-a"
                              to={`/departamento${property.id}`}
                            >
                              Click para ver
                              <span className="bi bi-chevron-right"></span>
                            </Link>
                          </div>
                          <div className="card-footer-a">
                            <ul className="card-info d-flex justify-content-around">
                              <li>
                                <h4 className="card-info-title">Area</h4>
                                <span>{property.area}</span>
                              </li>
                              <li>
                                <h4 className="card-info-title">Dormitorios</h4>
                                <span>{property.bedrooms}</span>
                              </li>
                              <li>
                                <h4 className="card-info-title">Baños</h4>
                                <span>{property.bathrooms}</span>
                              </li>
                              <li>
                                <h4 className="card-info-title">Garages</h4>
                                <span>{property.garages}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
              </div>
              <div className="propery-carousel-pagination carousel-pagination"></div>
            </div>

          {/* ======= Terrenos ======= */}

          <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="title-wrap d-flex flex-column flex-md-row justify-content-between align-items-md-center">
                    <div className="title-box order-md-1 mb-md-0">
                      <h2 className="title-a">Terrenos</h2>
                    </div>
                    <div className="title-link order-md-2">
                      <Link to="/terrenos">
                        Todos los terrenos <span className="bi bi-chevron-right"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className='carousel-menu'>
              <Swiper
                id="property-carousel"
                spaceBetween={30}
                slidesPerView={3}
                pagination={{ clickable: true }}
                loop={true}
                autoplay={{ delay: 5000 }}
                breakpoints={{
                  900: {
                    slidesPerView: 3, // Para pantallas de 900px o más grandes
                  },
                  0: {
                    slidesPerView: 1, // Para pantallas más pequeñas que 900px
                  }
                }}
              >
                {Terrenos.map((property, index) => (
                  <SwiperSlide key={index} className="carousel-item-b swiper-slide">
                    <Link to={`/terreno${property.id}`}>
                  <div className="card-box-a card-shadow">
                      <div className="img-box-a">
                        <img
                          loading='lazy'
                          src={property.image}
                          alt=""
                          className="img-a img-fluid"
                        />
                      </div>
                      <div className="card-overlay">
                        <div className="card-overlay-a-content">
                          <div className="card-header-a">
                            <h2 className="card-title-a">
                              <Link to={`/terreno${property.id}`}>
                                {property.title}
                                <br /> {property.location}
                              </Link>
                            </h2>
                          </div>
                          <div className="card-body-a">
                            <div className="price-box d-flex">
                              <span className="price-a">
                                {property.type} | {property.price}
                              </span>
                            </div>
                            <Link
                              className="link-a"
                              to={`/terreno${property.id}`}
                            >
                              Click para ver
                              <span className="bi bi-chevron-right"></span>
                            </Link>
                          </div>
                          <div className="card-footer-a">
                            <ul className="card-info d-flex justify-content-around">
                              <li>
                                <h4 className="card-info-title">Area</h4>
                                <span>{property.area}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
              </div>
              <div className="propery-carousel-pagination carousel-pagination"></div>
            </div>


          {/* ======= Locales Comercial ======= */}

          <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="title-wrap d-flex flex-column flex-md-row justify-content-between align-items-md-center">
                    <div className="title-box order-md-1 mb-md-0">
                      <h2 className="title-a">Locales Comercial</h2>
                    </div>
                    <div className="title-link order-md-2">
                      <Link to="/locales">
                        Todos los locales comercial <span className="bi bi-chevron-right"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className='carousel-menu'>
              <Swiper
                id="property-carousel"
                spaceBetween={30}
                slidesPerView={3}
                pagination={{ clickable: true }}
                loop={true}
                autoplay={{ delay: 5000 }}
                breakpoints={{
                  900: {
                    slidesPerView: 3, // Para pantallas de 900px o más grandes
                  },
                  0: {
                    slidesPerView: 1, // Para pantallas más pequeñas que 900px
                  }
                }}
              >
                {Locales.map((property, index) => (
                  <SwiperSlide key={index} className="carousel-item-b swiper-slide">
                    <Link to={`/local${property.id}`}>
                  <div className="card-box-a card-shadow">
                      <div className="img-box-a">
                        <img
                          loading='lazy'
                          src={property.image}
                          alt=""
                          className="img-a img-fluid"
                        />
                      </div>
                      <div className="card-overlay">
                        <div className="card-overlay-a-content">
                          <div className="card-header-a">
                            <h2 className="card-title-a">
                              <Link to={`/local${property.id}`}>
                                {property.title}
                                <br /> {property.location}
                              </Link>
                            </h2>
                          </div>
                          <div className="card-body-a">
                            <div className="price-box d-flex">
                              <span className="price-a">
                                {property.type} | {property.price}
                              </span>
                            </div>
                            <Link
                              className="link-a"
                              to={`/local${property.id}`}
                            >
                              Click para ver
                              <span className="bi bi-chevron-right"></span>
                            </Link>
                          </div>
                          <div className="card-footer-a">
                            <ul className="card-info d-flex justify-content-around">
                              <li>
                                <h4 className="card-info-title">Area Total</h4>
                                <span>{property.areat}</span>
                              </li>
                              <li>
                                <h4 className="card-info-title">Area Construida</h4>
                                <span>{property.areac}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
              </div>
              <div className="propery-carousel-pagination carousel-pagination"></div>
            </div>



          </section>
        </main>
      </>
    );
  }
}

export default Home;